import React from "react";

const Footer = ()=>{
return <div className='bg-[#ffd180] text-2xl mt-[360px] flex flex-col items-start h-[80px] px-8 py-1'>
<div className="flex items-start ">
         <a href="https://twitter.com/Dotordinals" target="_blank" className="social-link" rel="noreferrer"><img src='/assets/twitter.png'
  alt=""
  height={30}
  width={30}
  /></a>
        <a href="https://t.me/dotscription" target="_blank" className="social-link" rel="noreferrer"> <img src='/assets/telegram.png'
  alt=""
  height={30}
  width={30}
  /></a>
      <a href='https://www.dextools.io/app/en/avalanche/pair-explorer/0xb8a539aaddbc73ab51b4f5e8b39912070b1976d9' target="_blank" className=" mt-2 border-[#F73C6B] text-[#F73C6B] text-2xl font-semibold" rel="noreferrer"> 📈</a>

</div>
          <p className="text-[#737B7D] text-base flex items-center justify-center tracking-wide font-bold">© 2023-24 Dotordinals</p>

</div>
}
export default Footer ;