import React from "react";
import Header from "./Header";
import Description from "./Description";
import About from "./About";
import Tokenomics from "./Tokenomics";
import Dynamics from "./Dynamics";
import Footer from "./Footer";

const Content = ()=>{
return  <div className="flex flex-col gap-2">
<Header/>
<About/>
<Tokenomics/>
<Dynamics/>
<Description/>
<Footer/>
</div>

}
export default Content;