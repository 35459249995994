import React from "react";

const Tokenomics = ()=>{
return <div className='bg-[#2ED57380] text-2xl h-[450px] p-8 mt-4'>
<div className="flex w-full max-sm:gap-3">
<span className="w-[50%] flex items-center justify-center">
<img src='/assets/tokenomic.svg'
  alt=""
  height={500}
  width={300}
  />
  </span>
  <div className="flex gap-2 flex-col items-center justify-center w-[50%]">
     <label className="text-[42px] text-[#373F41] font-bold py-6">Tokenomics</label> 
  <span className="text-base text-[#4F4F4F] font-medium">
  <table className="border-solid border-[1px] border-teal-800">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount (Billion)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Liquidity Pool</td>
              <td>4.2</td>
            </tr>
            <tr>
              <td>Dotordinals Wood Foundation</td>
              <td>4.2</td>
            </tr>
            <tr>
              <td>Community Grants</td>
              <td>6.3</td>
            </tr>
            <tr>
              <td>team</td>
              <td>2.1</td>
            </tr>
            <tr>
              <td>Airdrop</td>
              <td>4.2</td>
            </tr>
          </tbody>
        </table>
        </span>
  </div>
</div>
</div>
}
export default Tokenomics;