import React from "react";

const About = () => {
return <div className='bg-white text-2xl h-[520px] p-8'>
<div className="flex w-full max-sm:gap-3">
  <div className="flex gap-2 flex-col items-center justify-center w-[50%] px-4">
     <label className="text-[42px] text-[#373F41] font-bold py-6">About us</label>
            <div className="py-6 px-9 flex flex-col shadow-2xl rounded-xl bg-[#4F55DD] mt-2 items-center">
     <span className="text-base text-white font-medium tracking-wide">Dotordinals allows you to inscribe PDC-20 tokens on blockchains.</span>
       <a href='https://dotordinals.io/' target="_blank" className="py-2.5 px-6 rounded-[4px] mt-4 bg-[#F73C6B] text-white text-base font-semibold" rel="noreferrer">D App</a>
       </div>
       <div className="p-4 flex flex-col shadow-2xl rounded-xl bg-[#4F55DD] mt-4 items-center tracking-wide">
        <span className="mt-4 text-white font-semibold text-base">Token Symbol: <span className="font-bold text-white">$WOOD</span></span>
<span className="mt-2 text-white font-semibold text-base">Contract Address:<span className="font-bold text-white">0xc44c3f2f971fdc1afb885f5acbe1905ab60bcb67</span></span>
<span className="mt-3 text-white font-semibold text-base">Total Supply: <span className="font-bold text-white">21 Billion</span></span>
  <a href='https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xc44c3f2f971fdc1afb885f5acbe1905ab60bcb67' target="_blank" className="py-2.5 px-6 rounded-[4px] mt-4 bg-[#F73C6B] text-white text-base w-[200px] font-semibold" rel="noreferrer">Buy Now ⌛</a>
       </div>
</div>
  <span  className="flex items-center justify-center w-[50%]">
  <img src='/assets/about.png'
  alt=""
  height={500}
  width={300}
  /></span>
</div>
</div>
}
export default About;