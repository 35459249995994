import React from "react";

const Header = ()=>{
return <div className='bg-[#ffd180] text-2xl h-[431px] p-8'>
<div className="flex justify-between">
<img src="/assets/wood.jpg" alt="Wood Logo" className="App-logo" height={10} width={80} />
<a href='https://www.dextools.io/app/en/avalanche/pair-explorer/0xb8a539aaddbc73ab51b4f5e8b39912070b1976d9' target="_blank" className="py-2.5 px-6 h-12 mt-4 text-[#F73C6B] text-base font-semibold" rel="noreferrer">Chart 📈</a>
</div>
<div className="flex w-full max-sm:gap-3 justify-center">
<span  className="w-[50%]">
<img src='/assets/main.svg'
  alt=""
  height={500}
  width={300}
  />
  </span>
  <div className="flex gap-2 flex-col items-center justify-center">
     <label className="text-[42px] text-[#373F41] font-bold py-6">Dotordinals <span className="font-extrabold text-teal-700">$WOOD</span></label>
  <span className="text-base text-[#737B7D] font-medium">Inscribe PDC-20 Tokens on Blockchains with Dotordinals</span>
   <span className="text-base text-[#737B7D] font-medium">Dotordinals allows you to inscribe PDC-20 tokens on blockchains.</span>
  <a href='https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xc44c3f2f971fdc1afb885f5acbe1905ab60bcb67' target="_blank" className="py-2.5 px-6 rounded-[4px] mt-4 border-[#F73C6B] text-[#F73C6B] text-base border-[1px] border-solid font-semibold" rel="noreferrer">Buy Now ⌛</a>
  </div>
</div>
</div>
}
export default Header ;