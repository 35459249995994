import React from "react";

const points =['WOOD is the foundational governance token of the Dotordinals ecosystem, a pioneering platform in the realm of blockchain technology. Specifically designed to empower users, WOOD facilitates the creation of ordinal inscription tokens and Non-Fungible Tokens (NFTs) across various blockchains, promoting a new era of digital asset ownership and creativity.',
'As a governance token, WOOD plays a vital role in the decentralized decision-making process of the Dotordinals network. Holders of WOOD are granted voting rights, enabling them to directly influence the future development and strategic direction of the Dotordinals ecosystem. This includes decisions on system upgrades, feature implementations, and community-driven initiatives.',
'The Dotordinals platform, powered by WOOD, is committed to providing a secure, transparent, and user-friendly environment for creating and trading digital assets. By leveraging the unique properties of blockchain technology, Dotordinals and WOOD are at the forefront of innovation in the digital economy, offering an accessible gateway to decentralized asset creation and management.',
'feature implementations, and community-driven initiatives. In addition to governance, WOOD is integrated into the core functionalities of Dotordinals. It acts as a key utility token within the platform, used in transactions, fees, and rewards, thereby aligning the incentives of all stakeholders. This integration ensures a robust and active ecosystem, fostering a vibrant community of creators, developers, and users.',
'In summary, WOOD is not just a cryptocurrency but a catalyst for decentralized digital creativity and governance, paving the way for a more inclusive and collaborative future in the digital asset space.'];


const Description = () => {
return <div className='bg-white text-2xl h-[431px] pb-10'>
<div className='bg-[#FF475780] text-2xl flex items-start justify-center h-[150px]'>
  <img src='/assets/description.png'
  alt=""
  height={500}
  className="-mt-12"
  width={300}
  />
</div>
<div className="flex w-full max-sm:gap-3 flex-col px-40 max-sm:px-4 mt-[96px] gap-4 mb-10">
  {points.map((point) => <div className="bg-[#4F55DD] p-4 text-sm text-white rounded-lg tracking-wide">{point}

  </div>
  )}
  </div>
</div>
}
export default Description;